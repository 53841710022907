import React, { useEffect } from 'react';
import './PrivacyPolicy.css';

const Datenschutzerklaerung = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='App'>
      <h1 className='page-header gradient-text' style={{fontSize: 'calc(20px + 1vw)', marginBlockStart: '0.5em'}}>Datenschutzerklärung</h1>
      <div className="privacy-policy">
        <section>
          <h2>1. Einleitung</h2>
          <p>
            Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer personenbezogenen Daten ist uns
            sehr wichtig. Mit dieser Datenschutzerklärung informieren wir Sie darüber, wie wir Ihre Daten im
            Zusammenhang mit der Erstellung und Nutzung von Benutzerkonten erheben, verarbeiten und speichern.
          </p>
        </section>

        <section>
          <h2>2. Verantwortliche Stelle</h2>
          <p>
            Verantwortliche Stelle im Sinne der Datenschutzgesetze ist: <strong>rate-my-meal.com</strong><br />
            <a href="mailto:info@rate-my-meal.com">info@rate-my-meal.com</a>
          </p>
        </section>

        <section>
          <h2>3. Erhebung und Verarbeitung personenbezogener Daten</h2>
          <p>
            Wenn Sie ein Benutzerkonto auf unserer Website erstellen, erheben wir die folgenden personenbezogenen Daten:
          </p>
          <ul>
            <li>E-Mail-Adresse: Zur Identifikation und für die Kommunikation.</li>
            <li>Passwort: Zur Sicherstellung der Sicherheit Ihres Kontos (in verschlüsselter Form gespeichert).</li>
          </ul>
          <p>
            Die Erhebung dieser Daten erfolgt, um Ihnen die Nutzung unserer Dienste zu ermöglichen und Ihre Benutzererfahrung zu personalisieren.
          </p>
        </section>

        <section>
          <h2>4. Zweck der Datenverarbeitung</h2>
          <p>
            Wir verwenden Ihre personenbezogenen Daten für folgende Zwecke:
          </p>
          <ul>
            <li>Erstellung und Verwaltung Ihres Benutzerkontos.</li>
            <li>Speicherung und Verwaltung Ihrer persönlichen Bewertungen der Gerichte.</li>
          </ul>
        </section>

        <section>
          <h2>5. Datenlöschung</h2>
          <p>
            Sie können Ihr Benutzerkonto jederzeit löschen. Bei der Löschung Ihres Kontos werden Ihre personenbezogenen Daten
            und alle damit verbundenen Bewertungen gemäß den gesetzlichen Aufbewahrungsfristen gelöscht, sofern keine anderen
            gesetzlichen Regelungen dem entgegenstehen.
          </p>
        </section>

        <section>
          <h2>6. Weitergabe von Daten</h2>
          <p>
            Ihre personenbezogenen Daten werden nicht an Dritte weitergegeben, es sei denn, dies ist zur Erfüllung eines Vertrages erforderlich oder wir sind gesetzlich dazu verpflichtet.
          </p>
        </section>

        <section>
          <h2>7. Ihre Rechte</h2>
          <ul>
            <li>Auskunft über die von uns gespeicherten personenbezogenen Daten zu verlangen.</li>
            <li>Die Berichtigung unrichtiger Daten zu verlangen.</li>
            <li>Die Löschung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Die Einschränkung der Verarbeitung Ihrer Daten zu verlangen.</li>
            <li>Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen.</li>
          </ul>
          <p>
            Bitte wenden Sie sich dazu an die oben angegebene verantwortliche Stelle.
          </p>
        </section>

        <section>
          <h2>8. Änderungen dieser Datenschutzerklärung</h2>
          <p>
            Wir behalten uns vor, diese Datenschutzerklärung jederzeit zu ändern. Die aktuelle Version wird auf unserer Website veröffentlicht.
            Bitte überprüfen Sie die Datenschutzerklärung regelmäßig.
          </p>
        </section>

        <section>
          <h2>9. Kontakt</h2>
          <p>
            Bei Fragen oder Anregungen zu dieser Datenschutzerklärung können Sie uns jederzeit unter der oben angegebenen E-Mail-Adresse kontaktieren.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Datenschutzerklaerung;