import React, { Component } from 'react';
import axios from 'axios';
import FoodItem from './FoodItem';
import { getFormattedDate } from '../utils';
import { RatingProvider } from './RatingContext';

const MENSA_IDS = [130, 131, 132, 138, 139];

class MealPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mealPlan: [],
      currentMensaIndex: 0
    };
  }

  componentDidMount() {
    this.fetchMealPlan();
  }

  fetchMealPlan() {
    if (this.state.currentMensaIndex >= MENSA_IDS.length) {
      return;
    }

    const mensaId = MENSA_IDS[this.state.currentMensaIndex];
    const API_URL = `https://sls.api.stw-on.de/v1/location/${mensaId}/menu/` + getFormattedDate(); 

    axios.get(API_URL)
      .then(response => {
        if (response.data.meals.length > 0) {
          var meals = [];
          response.data.meals.forEach(meal => {
            if (meal.lane.name.includes("Essen") || meal.lane.name.includes("Aktion"))
              meals.push(meal);
          });
          this.setState({ mealPlan: meals });
        } else {
          this.setState(prevState => ({
            currentMensaIndex: prevState.currentMensaIndex + 1
          }), this.fetchMealPlan());
        }
      })
      .catch(error => {
        console.error('Error fetching meal plan:', error);
      });
  }

  render() {
    return (
      <div className="mealplan-container">
        <h1 className='App-main-header'>Heutige Gerichte</h1>
        <RatingProvider>
          {this.state.mealPlan.length === 0 ? (<p className='food-header'>Heute leider keine Gerichte :(</p>) :
            this.state.mealPlan[0].price.student === "0.00" ? (<p className='food-header'>{this.state.mealPlan[0].name}</p>) :
              this.state.mealPlan.map((food, index) => (
                <FoodItem key={index} food={food} />
              ))}
        </RatingProvider>
      </div>
    );
  }
}

export default MealPlan;
