import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Optional: Create a CSS file for styling the footer

const Footer = () => {

    return (
        <footer className='footer'>
            <div className="footer-content">
                <Link to="/datenschutzerklaerung" className="footer-link">
                    Datenschutzerklärung
                </Link>
            </div>
        </footer>
    );
};

export default Footer;