import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { verifyUser } from "../../backend/data";
import { logIn } from "./LoginUtils";
import { AvatarContext } from "../AvatarContext";


function VerifyEmail() {
    const { token } = useParams();
    const { avatarState, setAvatarState } = useContext(AvatarContext);

    const verifyEmailOnLoad = () => {
        verifyUser(token);
        console.log("avatarState.userEmail: ", localStorage.getItem('userEmail'));
        logIn(localStorage.getItem('userEmail'), setAvatarState);
        setAvatarState({
            loggedIn: true,
            userEmail: localStorage.getItem('userEmail'),
            avatarColor: avatarState.avatarColor
        });
    };

    useEffect(() => {
        verifyEmailOnLoad();
    }, [token]);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
            className="App App-header blend-into-image background-blur"
        >
            <div
                style={{
                    height: "30vh",
                }}
            >
                <form >
                    <h2 className="">
                        Email successfully verified.
                    </h2>
                    <Link to="">
                        <button type="button">Homepage</button>
                    </Link>
                </form>
            </div>
        </div>
    );

}

export default VerifyEmail;