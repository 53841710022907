import '../style.css';
import React, { useContext, useEffect, useState } from 'react';
import { DNA, Grid } from 'react-loader-spinner';
import AvgRatingDisplay from './AvgRatingDisplay';
import { getData, getUserRatings } from '../backend/data';
import { AvatarContext } from './AvatarContext';
import { useHistory } from 'react-router-dom';

function PersonalRanking() {
    // State to store the fetched data
    const [data, setData] = useState([]);
    // State to track loading status
    const [isLoading, setIsLoading] = useState(true);
    const { avatarState, setAvatarState } = useContext(AvatarContext);
    const history = useHistory();

    // Fetch data from the API on component mount
    useEffect(() => {
        if (!avatarState.userEmail) {
            setIsLoading(false);
            return;
        }

        getUserRatings(avatarState.userEmail)
            .then(data => {
                const sortedData = data.sort((a, b) => (b.total_rating / b.rating_count) - (a.total_rating / a.rating_count));
                setData(sortedData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });

    }, [avatarState.userEmail]); // Empty dependency array means this effect runs once on mount

    return (
        <div className="App small-app">
            <h1 className='gradient-text page-header' style={{fontSize: ''}}>Personal Ranking</h1>
            {isLoading ? (
                <div className='loading-container'>
                    <DNA height={'150'} width={'150'} />
                    {/* <Grid height={'150'} width={'150'}/>  */}
                </div>
            ) : (
                !avatarState.userEmail ? (
                    <div>
                        <h2 style={{ color: 'wheat' }}>Please log in or create an account to view your personal ranking</h2>
                        <button className='button' onClick={() => history.push("/login")}>Log in / Sign up</button>
                    </div>
                ) : (
                    data.length === 0 ? (
                        <h2 style={{ color: 'wheat' }}>You haven't rated any dishes yet</h2>
                    ) : (
                        <table style={{ fontFamily: 'Neue Regrade Semibold', color: 'wheat', width: '100%', fontSize: '14px' }}>
                            <thead style={{ fontFamily: 'Neue Regrade Bold' }}>
                                <tr>
                                    <th style={{ width: '10%' }}>Rang</th>
                                    <th style={{ width: '65%' }}>Name</th>
                                    <th style={{ width: '25%' }}>Avg Ranking</th>
                                    {/* Add more columns as needed */}
                                </tr>
                            </thead>
                            <tbody >
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td className='table-row-padding'>{index + 1}</td>
                                        <td className='table-row-padding' style={{ fontSize: '15px' }}>{item.dish_name}</td>
                                        <td className='table-row-padding'>
                                            <AvgRatingDisplay value={item.total_rating / item.rating_count} precision={0.1} size={'small'} counter={item.rating_count} />
                                        </td>
                                        {/* Render more data as needed */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                )
            )}
        </div>
    );
}

export default PersonalRanking;