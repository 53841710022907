import React, { Component, useState, useEffect } from 'react';
import RatingComponent from './RatingComponent';
import { getRating } from '../backend/data';
import AvgRatingDisplay from './AvgRatingDisplay';


class FoodItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foodData: [],
      averageRating: 0,
      counter: 0
    };
  }

  async componentDidMount() {
    try {
      const meal = this.props.food.name;
      const data = await getRating(meal);
      this.setState({
        averageRating: data.rating,
        counter: data.Counter
      });
    } catch (error) {
      console.error('Failed to fetch rating:', error);
    }
  }

  updateAverageRating = (newRating) => {
    this.setState((prevState) => {
      const newCounter = prevState.counter + 1;
      const newAverageRating = ((prevState.averageRating * prevState.counter) + newRating) / newCounter;
      return {
        averageRating: newAverageRating,
        counter: newCounter
      }
    });
  };

  render() {
    const { food } = this.props;
    const { rating } = this.state;

    return (
      <div className='meal-box'>
        <h2 className='food-header'>{food.name}</h2>
        {rating !== -1 ? (
          <div>
            <AvgRatingDisplay name={food.name} precision={0.1} size={"medium"} value={this.state.averageRating} counter={this.state.counter} />
          </div>
        ) : (
          <p className='food-details'>No ratings yet.</p>
        )}
        <div className='separator'></div>
        <div>
          <RatingComponent name={food.name} size={"large"} precision={0.5} onSubmitRating={this.updateAverageRating} />
        </div>
      </div>
    );
  }
}

export default FoodItem;
