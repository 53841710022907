import React, { useEffect, useState } from "react";
import "./style.css";
import "../../style.css"
import SignInForm from "./SignIn";
import SignUpForm from "./SignUp";
import MobileSignInForm from "./MobileSignIn";
import MobileSignUpForm from "./MobileSignUp";
import { useHistory } from "react-router-dom";

function Login() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isAnimating, setIsAnimating] = useState(false);
  const [type, setType] = useState("signIn");
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOnClick = (text) => {
    setIsAnimating(true);
    setTimeout(() => {
      setType(text);
      setIsAnimating(false);
    }, 200);
  };

  const getFormClass = (formType) => {
    if (isAnimating) {
      if (formType === type) {
        return formType === "signIn"
          ? "form slide-fade-out-to-top visible"
          : "form slide-fade-out-to-bottom visible";
      } else {
        return formType === "signIn"
          ? "form slide-fade-in-from-top visible"
          : "form slide-fade-in-from-bottom visible";
      }
    }
    return formType === type ? "form visible" : "form hidden";
  };

  const containerClass =
    "container " + (type === "signUp" ? "right-panel-active" : "");
  return (
    <div className="App-header blend-into-image background-blur">
      <div className="App flex-container">
        {isMobile ? (
          <div className="mobile-container">
            <div className={getFormClass("signIn")}>
              <MobileSignInForm onSwitch={() => handleOnClick("signUp")} history={history}/>
            </div>
            <div className={getFormClass("signUp")} >
              <MobileSignUpForm onSwitch={() => handleOnClick("signIn")} />
            </div>
          </div>
        ) : (
          <div className={containerClass} id="container">
            <SignUpForm />
            <SignInForm history={history}/>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>Welcome Back!</h1>
                  <p>
                    To keep connected with us please login with your personal info
                  </p>
                  <button
                    className="ghost"
                    id="signIn"
                    onClick={() => handleOnClick("signIn")}
                  >
                    Sign In
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <p>Enter your personal details and start journey with us</p>
                  <button
                    className="ghost "
                    id="signUp"
                    onClick={() => handleOnClick("signUp")}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div >
  );
}

export default Login;
