import React, { createContext, useState } from 'react';

export const RatingContext = createContext();

// used to store the state of the rating dialog and disable the other rating buttons
export const RatingProvider = ({ children }) => {
  const [hasRated, setHasRated] = useState(localStorage.getItem('hasRated') === 'true');
  const [triggerRerender, setTriggerRerender] = useState(false);

  const confirmRating = () => {
    setHasRated(true);
    localStorage.setItem('hasRated', true);
    setTriggerRerender(!triggerRerender);
  };

  return (
    <RatingContext.Provider value={{ hasRated, confirmRating, triggerRerender }}>
      {children}
    </RatingContext.Provider>
  );
};