import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const CustomDialog = ({ open, onClose, title, contentText, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{
            '& .MuiDialog-paper': {
                backgroundColor: '#484c54',
            },
            '& .MuiDialogTitle-root': {
                color: 'orange',
                fontFamily: 'Neue Regrade Bold',
            },
            '& .MuiDialogContentText-root': {
                fontFamily: 'Neue Regrade Bold',
            },
        }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: 'wheat' }}>{contentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <button onClick={onClose} autoFocus>Cancel</button>
                <button style={{ background: '#ff4c00', border: '1px solid #ff4c00' }} onClick={onConfirm}>Confirm</button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;