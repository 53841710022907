import { addUser } from "../../backend/data";
const bcrypt = require('bcryptjs');

const SPECIAL_CHARACTERS = "!@#$%^&*(),.?\":{}|<>-/§=+";

export const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const hasSpecialCharacter = password => {
    const specialCharacterRegex = new RegExp(`[${SPECIAL_CHARACTERS.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}]`);
    return specialCharacterRegex.test(password);
}

export const handleSignUpSubmit = async (evt, state, setState) => {
    evt.preventDefault();

    const { email, password, confirmPassword } = state;

    const verificationStatus = await fetchData(`https://rate-my-meal.com/api/isver/${email}`);
    if (verificationStatus.length !== 0 && verificationStatus[0].email_verified === 1) {
        alert(`Email already verified`);
        return;
    }
    const isPasswordValid = await checkPassword(password, confirmPassword);
    if (!isPasswordValid) {
        return;
    }

    try {
        await addUser(email, password);
        alert(`A verification email has been sent to ${email}`);
        localStorage.setItem('userEmail', email);
    } catch (error) {
        alert('Error adding user:', error.message);
    }

    setState({
        email: "",
        password: "",
        confirmPassword: ""
    });
}


export const checkPassword = (password, confirmPassword) => {
    if (password.length < 6) {
        alert("Password must be at least 6 characters long");
        return false;
    }
    if (!hasSpecialCharacter(password)) {
        alert(`Password must contain at least one special character: ${SPECIAL_CHARACTERS}`);
        return false;
    }
    if (password !== confirmPassword) {
        alert("Password and confirmed password do not match");
        return false;
    }
    return true;
}

export const handleSignInSubmit = async (evt, state, setState, setAvatarState, history) => {
    evt.preventDefault();

    const { email, password } = state;

    if (!await checkEmail(email) ) {
        return;
    }

    // compare hashed password with user input password
    const hashedPassword = await fetchData(`https://rate-my-meal.com/api/hashpw/${email}`);
    const isPasswordCorrect = await bcrypt.compare(password, hashedPassword[0].password);

    if (!isPasswordCorrect) {
        alert(`Incorrect password`);
        return;
    }

    logIn(email, setAvatarState);

    history.push('');

    setState({
        email: "",
        password: "",
        confirmPassword: ""
    });
}

// just log the user in
export const logIn = (email, setAvatarState) => {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userEmail', email);

    setAvatarState({
        loggedIn: true,
        userEmail: email,
        avatarColor: generateRandomColor()
    });

    alert(`Welcome back, ${email}`);
}

export const generateRandomColor = () => {
    const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    // Generate RGB values within a range that avoids very light colors
    const r = getRandomValue(0, 200);
    const g = getRandomValue(0, 200);
    const b = getRandomValue(0, 200);

    // Convert RGB to hex
    const toHex = (value) => value.toString(16).padStart(2, '0');
    const color = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

    return color;
  };

export const checkEmail = async (email) => {
    if (!isValidEmail(email)) {
        alert("Invalid email address");
        return false;
    }

    const verificationStatus = await fetchData(`https://rate-my-meal.com/api/isver/${email}`);
    if (verificationStatus.length === 0) {
        alert(`Please sign up first`);
        return false;
    }
    if (verificationStatus[0].email_verified === 0) {
        alert(`Please verify your email first`);
        return false;
    }
    return true;
}

export const fetchData = async (url) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}