/************************************************
 * Section: Global Ranking
 ************************************************/

/**
 * adds a global rating to that Meal
 * @param {Meal} m 
 * @param {Rating} r 
 */
export async function putData(m, r){
    const url = 'https://rate-my-meal.com/api/insert-data';
    const data = { name: m, rating: r };

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Data added:', result);
    } catch (error) {
        console.error('Error adding data:', error);
    }
};

/**
 * gets whole global Rating data
 * @returns 
 */
export async function getData(){
    const url = 'https://rate-my-meal.com/api/data/';

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Data received:', result);
        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

/**
 * gets a global rating from a specific Meal
 * @param {Meal Name} meal 
 * @returns 
 */
export async function getRating(meal){
    const url = 'https://rate-my-meal.com/api/data/' + meal;

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Data received:', result);
        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


/************************************************
 * Section: User Management 
 ************************************************/

/**
 * adds a User to the Database
 * @param {User Mail} username 
 * @param {Password} password 
 */
export async function addUser(username, password) { 
    const url = 'https://rate-my-meal.com/api/users';
    const data = { username, password };

    try {
        const response = await fetch(url, {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.text();
            console.error('Error Response Body:', errorData);
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // Erfolgreiche Antwort verarbeiten
        const result = await response.json();
        console.log('User updated successfully:', result);
    } catch (error) {
        console.error('Error updating user:', error);
    }
}


/**
 * Info to Server to create Token and send the user a Mail with reset Token
 * @param {User Mail} email 
 */
export async function resetPw(email) { 
    const url = 'https://rate-my-meal.com/api/password-reset'; // Ersetze durch deine tatsächliche URL
    const data = { email }; 

    try {
        const response = await fetch(url, {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), 
        });

        if (!response.ok) {
            const errorData = await response.text();
            console.error('Error Response Body:', errorData);
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const result = await response.json();
        console.log('Reset Mail gesendet?:', result);
    } catch (error) {
        console.error('Error updating user:', error);
    }
}


/**
 * sets the new Passwort for a User with that specific Reset Token
 * @param {new Passwort} newpw 
 * @param {Reset Token} restoken 
 */
export async function setnewPw(newpw, restoken) {
    const url = 'https://rate-my-meal.com/api/changepw';
    const data = { newpw, restoken };

    try {
        const response = await fetch(url, {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.text();
            console.error('Error Response Body:', errorData);
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const result = await response.json();
        console.log('set new PW', result);
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

/**
 * delets User from the Database
 * @param {Name} username 
 */
export async function deleteUser(username){
    try {
        const response = await fetch('https://rate-my-meal.com/api/deleteuser', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username }) 
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(errorMessage);
        }

        const data = await response.json();
        console.log(data.message); 
    } catch (error) {
        console.error('Fehler beim Löschen des Nutzers:', error.message);
    }
    //TODO personal ranking automnatisch löschen
};

/**
 * gets Passwort reset Token without sending a Mail
 * @param {Mail} email 
 * @returns 
 */
export async function resetPwAutoToken(email) {  
    const url = 'https://rate-my-meal.com/api/password-reset2'; 
    const data = { email }; 

    try {
        const response = await fetch(url, {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), 
        });

        if (!response.ok) {
            const errorData = await response.text();
            console.error('Error Response Body:', errorData);
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const result = await response.json();
        return result.message;
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

/**
 * gets User's ID by his mail
 * @param {User Mail} mail 
 * @returns 
 */
async function getUserID(mail) {
    const url = 'https://rate-my-meal.com/api/userID/' + mail;

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();

        // Überprüfen, ob das Ergebnis ein leeres Array ist
        if (Array.isArray(result) && result.length === 0) {
            return -1;  // User existiert nicht
        } else {
            return result[0].id;  // User existiert
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

/**
 * Verifys the User
 * @param {Verify Token} token 
 * @returns 
 */
export async function verifyUser(token) {
    const url = 'https://rate-my-meal.com/api/confirm-email/' + token;

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();

        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

/**
 * delets Users local Ranking by his mail
 * @param {User Mail} mail 
 * @returns 
 */
export async function deleteRanking(mail){

    let userID = await getUserID(mail);
    if (userID == -1) {
        console.log("User not found.");
        return;
    }
    try {
        const url = `https://rate-my-meal.com/api/deleteRanking/${userID}`;

        const response = await fetch(url, {
            method: 'DELETE'
        });

        // Überprüfe, ob die Anfrage erfolgreich war
        if (response.ok) {
            const result = await response.json();
            console.log('Erfolg:', result.message);
        } else {
            const errorMessage = await response.json();
            console.error('Fehler:', errorMessage.message);
        }
    } catch (error) {
        console.error('Fehler beim Löschen der Bewertungen:', error);
    }
};

/************************************************
 * Section: personal Ranking
 ************************************************/

/**
 * adds a Dish to the Database
 * @param {meal} name 
 */
async function addDish(name) {
    const url = 'https://rate-my-meal.com/api/dishes'; 
    const data = { name };

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.text();
            console.error('Error Response Body:', errorData);
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // Erfolgreiche Antwort verarbeiten
        const result = await response.json();
        console.log('Dish added successfully:', result);
    } catch (error) {
        console.error('Error updating user:', error);
    }
}


/**
 * Saves the rating from a registered User 
 * @param {User Mail} mail 
 * @param {Meal Name} meal 
 * @param {Rating from 1-5} rating 
 * @returns 
 */
export async function addRating(mail, meal, rating) {


    let mealID = await checkMeal(meal);

    // Überprüfung, ob das Meal existiert
    if (mealID == -1) {
        console.log("existiert nicht, wird hinzugefügt...");
        await addDish(meal);
    }
    mealID = await checkMeal(meal);
    let userID = await getUserID(mail);
    if (mealID == -1 || userID == -1) {
        console.log("MealID: " + mealID);
        console.log("UserID: " + userID);
        console.log("Meal oder User nicht gefunden");
        return;
    }
    addUserRating(userID, mealID, rating);
}

/**
 * gets Meal ID or -1 if not exists
 * @param {Name} meal 
 * @returns 
 */
async function checkMeal(meal) {
    const url = 'https://rate-my-meal.com/api/meal/' + meal;

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();

        // Überprüfen, ob das Ergebnis ein leeres Array ist
        if (Array.isArray(result) && result.length === 0) {
            return -1;  // Meal existiert nicht
        } else {
            return result[0].id;  // Meal existiert
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

/**
 * adds the personal Rating to Database
 * @param {*} userID 
 * @param {*} mealID 
 * @param {*} rating 
 */
async function addUserRating(userID, mealID, rating) {
    const url = 'https://rate-my-meal.com/api/addRating'; 
    const data = { userID, mealID, rating };

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.text();
            console.error('Error Response Body:', errorData);
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        // Erfolgreiche Antwort verarbeiten
        const result = await response.json();
        console.log('Rating added successfully:', result);
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

/**
 * gets all Ratings from a User
 * @param {User Mail} mail 
 * @returns 
 */
export async function getUserRatings(mail){
    const url = `https://rate-my-meal.com/api/getRatings/${mail}`;

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
