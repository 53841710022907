import { Mail } from "@mui/icons-material";


function Imprint() {
    return (
        <div className="App small-app">
            <h1 className="page-header gradient-text" >Kontakt</h1>
            <div style={{ fontSize: 'calc(1em + 5px)', color: 'wheat', textAlign: 'start', margin: '2% 10%' }}>
                <p style={{ fontSize: 'calc(0.5em + 5px)' }}>Angaben gemäß § 5 TMG:</p>
                {/* <h3>Anschrift:</h3>
                <div className="indent-text">
                    <p>Max Mustermann</p>
                    <p>Musterstraße 1</p>
                    <p>12345 Musterstadt</p>
                    <p>Vertreten durch: Max Mustermann</p>
                </div> */}
                <h3>Kontakt:</h3>
                <div className="indent-text" style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Mail style={{marginRight: '2%'}}/>
                    <p>info@rate-my-meal.com</p>
                </div>
                <h3>Urheberrechtsverweis</h3>
                <div className="indent-text">
                    <p>Alle Bilder, die auf dieser Website zu finden sind, wurden mit KI erstellt.</p>
                </div>
            </div>
        </div>
    )
};

export default Imprint;