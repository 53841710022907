import React from "react";
import { checkEmail } from "./LoginUtils";
import { resetPw } from "../../backend/data";

function PasswordReset() {
    const handleSubmit= (event) => {
        event.preventDefault();
        if (event.target[0].value === "") {
            alert("Please enter your email");
            return;
        }
        if (!checkEmail(event.target.email.value)) {
          return;
        }
        resetPw(event.target.email.value);
        alert("Password reset link sent to: " + event.target.email.value);
    }

    return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      className="App App-header blend-into-image background-blur"
    >
      <div
        style={{
          height: "45vh", margin: '0 10px'
        }}
      >
        <form onSubmit={handleSubmit}>
          <h1 style={{ marginBottom: "calc(10% + 15px)" }} className="">
            Reset Password
          </h1>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            style={{ marginBottom: "calc(7% + 10px)" }}
          />
          <button type="submit">Send Reset Link</button>
        </form>
      </div>
    </div>
  );
}

export default PasswordReset;
