import React, { createContext, useState } from 'react';

export const AvatarContext = createContext();

export const AvatarProvider = ({ children }) => {
  const [avatarState, setAvatarState] = useState({
    loggedIn: false,
    userEmail: '',
    avatarColor: 'grey'
  });

  return (
    <AvatarContext.Provider value={{ avatarState, setAvatarState }}>
      {children}
    </AvatarContext.Provider>
  );
};